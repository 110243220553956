export default {
  locale: "no",

  App: {
    locale: "no",
    zoomInTip: "Zoom inn",
    zoomOutTip: "Zoom ut",
    trackTip: "Min posisjon",
    basemapGroup: "Bakgrunnskart"
  },

  NavBar: {
    locale: "no",
    menu: "Meny",
    header: "Renovasjon i Nordhordland, Gulen og Solund",
    avvik: "Meld avvik",
    naering: "For næring",
    gjenvinningsstasjonar: "Gjenvinningsstasjonar"
  },

  Search: {
    locale: "no",
    title: "Finn henteruter og næraste returpunkt",
    subtitle: "– søk med adressa di her:",
    addressSearchPlaceholder: "Tast inn adresse",
    addresses: "Adresser"
  },

  IndexView: {
    locale: "no",
    hushald: "Hushald",
    hytteeigar: "Hytteeigar",
    naering: "Næring",
    hushaldIntro:
      "Informasjon om henteruter, næraste gjenvinningsstasjon, mobil gjenvinningsstasjon og rydderuter der du bur:",
    hytteigarIntro:
      "Informasjon om hyttekonteinarar, næraste gjenvinningsstasjon, mobil gjenvinningsstasjon og rydderuter der du har hytte:",
    naeringIntro:
      "NGIR tilbyr totalløysningar innan avfall for næringsliv og bedrifter - ta kontakt med ein bedriftsrådgjevar",
    hushaldIntro2: "\u2026 eller gå til ei av dine lagra adresser:",
    naeringBtn: "Gå til næring",
    addresses: "Adresser"
  },

  PageView: {
    locale: "no",
    homePage: "gå til hjemmesiden"
  },

  MapPageView: {
    locale: "no",
    title: "Opningstider:",
    monday: "Måndag",
    tuesday: "Tysdag",
    wednesday: "Onsdag",
    thursday: "Torsdag",
    friday: "Fredag",
    saturday: "Laurdag",
    sunday: "Søndag",
    unntak: "Unntak"
  },

  Page404: {
    locale: "no",
    pageNotFound: "Siden ble ikke funnet",
    checkAddress: "Vennligst kontroller adressen eller",
    homePage: "gå til forsida"
  },

  Hours: {
    locale: "no",
    today: "I dag",
    showAll: "Sjå alle",
    showOpeningHours: "Opningstider",
    hideOpeningHours: "Skjul opningstider",
    desc: "Klikk deg inn på den aktuelle mottaksstasjonen eller tenesta for opningstider og annan informasjon:"
  },

  HoursItem: {
    locale: "no",
    monday: "Måndag",
    tuesday: "Tysdag",
    wednesday: "Onsdag",
    thursday: "Torsdag",
    friday: "Fredag",
    saturday: "Laurdag",
    sunday: "Søndag",
    unntak: "Unntak"
  },

  News: {
    locale: "no",
    title: "AKTUELT NO",
    seeAll: "Sjå alle >"
  },

  NewsAllView: {
    locale: "no",
    title: "ALLE AKTUELT-SAKER SAMLA"
  },

  Footer: {
    locale: "no",
    omoss: "Om NGIR",
    personvern: "Personvern",
    Tilgjengelighetserklæring: "Tilgjengelighetserklæring",
    followFacebook: "NGIR på Facebook",
    skjema: "Skjema",
    followInstagram: "NGIR på Instagram",
    hushald: "Hushald/Hytte",
    næring: "Næring",
    aktuelt: "Aktuelt no:",
    nyheter: "Nyhende:",
    meldAvvik: "Meld avvik",
    aapenhetsloven: "Åpenhetsloven",
    resepsjon: "Resepsjon"
  },

  Bookmarks: {
    locale: "no",
    bookmarks: "Vel ei adresse i lista",
    noBookmarks: "Du har ingen lagra adresser. Skriv inn ei adresse."
  },

  HushaldView: {
    locale: "no",
    gobackAndSearch: "gå tilbake og søk på ny adresse",
    hushald: "Hushald",
    sliksortererdu: "Slik sorterer du",
    addBookmark: "lagre adresse",
    isBookmarked: "adressa er lagra",
    removeBookmark: "Trykk for å slette lagra adresse."
  },

  HytteView: {
    locale: "no",
    gobackAndSearch: "gå tilbake og søk på ny adresse",
    hytte: "Hytte",
    addBookmark: "lagre adresse",
    isBookmarked: "adressa er lagra",
    removeBookmark: "Trykk for å slette lagra adresse."
  },

  SecondNavBar: {
    locale: "no",
    showOpeningHours: "Opningstider",
    hideOpeningHours: "Skjul opningstider"
  },
  Hentedag: {
    locale: "no",
    tommekalender: "Tømmekalender",
    tommekalenderwnumber: "Tømmekalender for rute {route}",
    thisWeek: "Denne veka (veke {{veke}})",
    nextWeek: "Neste veke (veke {{veke}})",
    hentedag: "Hentedag",
    type: "Fraksjon",
    noResults: "Kan ikkje finne hentedag for adressa",
    restavfall: "Restavfall",
    bioavfall: "Bioavfall",
    papp: "Papp og papir",
    plast: "Plast"
  },
  Konteinerleie: {
    local: "no",
    konteinerleie: "Konteinarleige",
    konteinerleieForSone: "Konteinarleige for {sone}",
    konteinarleigeOgTransport: "Konteinarleige og transport",
    noResults: "Kan ikkje finne konteinarleige for adressa",
    pris: "Pris inkl. mva.",
    sjaMerOm: "Sjå meir om konteinarleige og transport"
  },

  Hyttekonteinarar: {
    local: "no",
    closest: "Næraste hyttekonteinar",
    loadingClosest: "Beregner rute til næraste hyttekonteinar",
    noResults: "Kan ikkje finne hyttekonteinar for adressa",
    kjoretid: "Beregnet kjøretid"
  },

  Glasskonteinere: {
    locale: "no",
    closest: "Næraste glas- og metallkonteinar",
    loadingClosest: "Beregner rute til næraste glas- og metallkonteinar",
    placement: "Plassering",
    tommeveker: "Tømmes følgjande veker",
    kjoretid: "Beregnet kjøretid"
  },

  Hentepunkt: {
    closest: "Næraste hentepunkt for avfallsspann",
    loadingClosest: "Finner næraste hentepunkt for avfallsspann",
    displayingPickupPoints:
      "Klikk på hentepunkt i kartet for å få opp informasjon om når dei sist vart tømt. Grøn farge betyr at renovasjonsbilen har vore på punktet og henta avfall, brun farge betyr at bilen ikkje har tømt på punktet enda/eller at det ikkje har vore sett fram spann. Hugs at tømmetidspunkt kan variera frå veke til veke, og at det kan vera forseinkingar i kartoppdateringa. Ein må setja fram spanna til kl. 07:00 for å kunna vera sikker på at spannet vert tømt."
  },

  Gjenvinningsstasjon: {
    locale: "no",
    header: "Gjenvinningstasjonar",
    placement: "Namn",
    mandag: "Måndag",
    tirsdag: "Tysdag",
    onsdag: "Onsdag",
    torsdag: "Torsdag",
    fredag: "Fredag",
    lørdag: "Laurdag",
    unntak: "Unntak",
    meirInfo: "Sjå meir informasjon om gjenvinningsstasjonar",
    openingHoursToday: "Opningstider i dag"
    // forMoreInfoClickOnButton: "For meir informasjon"
  },

  Varsel: {
    locale: "no",
    header: "Varsel (SMS)",
    headingText: "Utsende SMS-varsel til denne adressa",
    clickToShowInMap:
      "Klikk på eit varsel for å sjå området som blei varsla i kartet.",
    noResults: "Ingen utsende SMS-varsel funne for denne adressa"
  },

  BilBatvrak: {
    locale: "no",
    closest: "Bil- og båtvrak",
    placement: "Namn",
    monday: "Måndag",
    tuesday: "Tysdag",
    wednesday: "Onsdag",
    thursday: "Torsdag",
    friday: "Fredag",
    saturday: "Laurdag",
    unntak: "Unntak",
    meirInfo: "Sjå meir informasjon om bil- og båtvrak."
  },

  AndreTjenester: {
    locale: "no",
    header: "Andre tenester",
    intro: "NGIR tilbyr følgjande tenester",
    konteinarleige: "Konteinarleige",
    steinsandgrus: "Stein og grus",
    radgjeving: "Rådgjeving næring",
    farligavfall: "Farleg avfall",
    deponi: "Deponi",
    kompostjord: "Kompostjord",
    koyretoy: "Køyretøy og campingvognar",
    fritidsbatar: "Fritidsbåtar"
  },

  MellombelsAvfallsmottak: {
    header: "Mobil gjenvinningsstasjon",
    meirInfo: "Sjå meir informasjon om mobile gjenvinningsstasjonar",
    hentedag: "Hentedag",
    kommentar: "Kommentar",
    noResults: "Kan ikkje finne mobil gjenvinningsstasjon for adressa",
    apningstid: "Åpen"
  },

  AvvikDropdown: {
    locale: "no",
    avvik: "Avvik",
    registrerte: "Registrerte avvik",
    intro: "I kartet ser du dei sist registrerte avvika i området.",
    underBehandling: "Under behandling",
    ubehandlet: "Ubehandla",
    tegnforklaring: "Teiknforklaring",
    avvikmeldings: "Meld avvik"
  },

  Nav: {
    locale: "no",
    meny: "MENY",
    naering: "Næring",
    sliksortererdu: "Slik sorterer du",
    header: "Næring",
    leigecontainerar: "Leige container",
    steinsandgrus: "Stein, sand og grus",
    kompostjord: "Kompostjord",
    bilvrak: "Levere bilvrak",
    batvrak: "Levere båtvrak"
  },

  NaeringView: {
    locale: "no",
    naering: "Næring",
    sliksortererdu: "Slik sorterer du",
    header: "Næring",
    radgjeving: "Rådgjeving",
    konteinarleige: "Containerleie",
    steinsandgrus: "Stein, grus og pukk",
    farligavfall: "Farleg avfall",
    deponi: "Avfall til deponi",
    mottakskrav: "Levere avfall?"
  },

  Skjema: {
    locale: "no",
    cancel: "Avbryt",
    save: "Send inn",
    errorHeader: "Noko gjekk galt!",
    required: "Alle felt merka med (*), må fyllast ut",
    sensitive: "Unngå sensitive opplysningar i skjemaet",
    errorMsg: "Kunne ikkje sende skjema, forsøk ein gong til.",
    successHeader: "Skjema sendt inn",
    successMsg:
      "<p>Du vil få tilsendt svar, når saka di er handsama.</br>Du har fått saks-id: {0}." +
      "<br><br>Vennleg helsing NGIR</p>",
    ok: "Ok",
    toHomePage: "Gå til forsida",
    soknadEndring: "Bestilling eller endring av renovasjon",
    soknadDispSlam:
      "Søknadsskjema for fritak frå slamgebyret og slamtømmeordinga",
    soknadDispFritak: "Søknadsskjema for dispensasjon/fritak frå renovasjon",
    soknadMinirenseanlegg: "Innmelding av minireinseanlegg i slamtømmeordning",
    clear: "Slett",
    today: "I dag",
    labelVedleggSlam:
      "Slamtømmeforskrifta § 10: Ved søknad om gebyrfritak, der slamanlegg ikkje skal nyttast i fritaksperioden, skal stoppekran for vassforsyning plomberast. Det setjast krav til dokumentasjon om utført arbeid. Slamanlegg må tømmast for slam før fritaket vert innvilga. Last opp naudsynt dokumentasjon under",
    labelVedleggFritak: "Vedlegg",
    labelVedleggBestilling: "Vedlegg",
    labelVedleggMinirenseanlegg: "Vedlegg",
    confirmCorrectInformation:
      "Eg bekreftar at opplysningar gjeve i søknad er korrekt"
  },

  Collapsible: {
    clickToView: "(klikk for å vise)"
  },

  Attachments: {
    label: "Vedlegg",
    select: "Last opp",
    comment: "Beskrivelse",
    uploadError: "Noe gikk galt under opplasting, vennligst prøv igjen!"
  }
};
