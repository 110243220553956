export default {
  ngirkartUrl: "https://www.ngirkart.no/",
  adaptiveUrl: "https://www.nordhordlandskart.no/",
  routeServiceUrl: "https://p-ngir-visma-integration.azurewebsites.net",
  search: {
    searchUrl: "https://av-crawler-search.azurewebsites.net/api/search",
    index: "page",
    domain: "www.ngir.no"
  },
  appName: "NGIR",
  cmsSiteId: 1,
  mapProjCode: "3857",
  mapProjection: "EPSG:3857",
  minZoom: 3,
  maxZoom: 18,
  fitViewAndZoom: {
    in: 18,
    out: 14
  },
  initExtent: [597216, 8421807, 865421, 8813002],
  themes: {
    forsidelenker: {
      uuid: "bc7ddaf3-6c8b-46ed-81b7-7512a7a334f5",
      columns: ["uri", "image", "text", "priority"]
    },
    kommuneareal: {
      uuid: "70a565e2-6f2d-4b92-9718-1d203afaee4b",
      columns: ["komm", "navn", "oppdateringsdato"],
      intersectFilter: {
        name: "geometri",
        comparisonOperator: "ST_INTERSECTS",
        netType: "geometry",
        logicalOperator: "AND",
        value: undefined
      }
    },
    gjenvinningsstasjoner: {
      uuid: "21a4fb13-cba7-4925-8468-b977d371c137",
      columns: [
        "navn",
        "mandag",
        "tirsdag",
        "onsdag",
        "torsdag",
        "fredag",
        "lordag",
        "sondag",
        "unntak",
        "gjenvinningsstasjon",
        "bilbatvrak",
        "is_naering",
        "is_privat",
        "sort",
        "description",
        "uri",
        "id"
      ],
      filter: {
        sortColumns: [
          {
            name: "sort"
          }
        ]
      }
    },
    varsel: {
      uuid: "53609fcb-b5f4-45ae-aa01-deb03d8bafe5",
      columns: [
        "id",
        "name",
        "textmessage",
        "statuscode",
        "errormessage",
        "comment",
        "send_date",
        "created_date",
        "sent_to",
        "sent_to_type"
      ],
      filter: {
        sortColumns: [
          {
            name: "id"
          }
        ]
      }
    },
    hentedag: {
      uuid: "2a060745-b989-44bf-9604-0d7a6042c38c",
      columns: [
        "fraksjon_dynamisk",
        "fraksjon_dynamisk_neste",
        "sone_ngir_2017",
        "hentedag",
        "hentedag_nesteveke",
        "kommentar",
        "aktuelluke",
        "nesteuke"
      ],
      intersectFilter: {
        name: "geom",
        comparisonOperator: "ST_INTERSECTS",
        netType: "geometry",
        logicalOperator: "AND",
        value: undefined
      }
    },
    konteinerleie: {
      uuid: "2763659a-dd2f-431f-a99c-86688a586dd8",
      columns: [
        "pristilti",
        "pristilfortifem",
        "pristiinkmva",
        "prisfortifeminkmva",
        "pristieksmva",
        "prisfortifemeksmva",
        "sone"
      ],
      intersectFilter: {
        name: "geom",
        comparisonOperator: "ST_INTERSECTS",
        netType: "geometry",
        logicalOperator: "AND",
        value: undefined
      }
    },
    glasskonteinere: {
      uuid: "64ba41e1-9e39-4d70-9c2b-107784867761",
      columns: [
        "plassering",
        "volum",
        "kommune",
        "rutenr",
        "tord",
        "farge",
        "lenke",
        "glasa",
        "tevling",
        "tal"
      ]
    },
    varhaustrydding: {
      uuid: "43a85783-f861-449a-8dfd-1f111a41751b",
      columns: ["id", "navn", "hdato", "kommentar"],
      intersectFilter: {
        name: "geom",
        comparisonOperator: "ST_INTERSECTS",
        netType: "geometry",
        logicalOperator: "AND",
        value: undefined
      }
    },
    hyttekonteinarar: {
      uuid: "ea1500a3-1e84-496a-97bf-28f88baad1ee",
      columns: ["id", "name", "route"],
      intersectFilter: {
        name: "geom",
        comparisonOperator: "ST_INTERSECTS",
        netType: "geometry",
        logicalOperator: "AND",
        value: undefined
      }
    },

    eternit: {
      uuid: "30eb4127-19c1-410d-8e22-c59b7380f770",
      columns: [
        "navn",
        "kommunenavn",
        "hdato",
        "tidsrom",
        "datofra",
        "datotil",
        "linkngir",
        "kommentar",
        "tidsrom"
      ]
    },
    aktuelt: {
      uuid: "51336344-d0f2-46af-bd2f-1942f5426b72",
      columns: [
        "id",
        "tittel",
        "innhold",
        "aktiv",
        "prioritet",
        "bilde",
        "date_created",
        "kategorier"
      ]
    },
    nyhetskategori: {
      uuid: "cae8f33e-a539-488a-9327-241f79a38bc4",
      columns: ["id", "tag"]
    },
    nyheter: {
      uuid: "403db80b-92f7-4a16-90d2-c0be32f93444",
      columns: ["id", "tittel", "innhold", "aktiv", "bilde", "date_created"]
    }
  },
  forms: {
    bestillingSkjema: {
      uuid: "72e8eda9-05e3-4084-b6e5-737a029bbfa5"
    },
    fritakSkjema: {
      uuid: "c702ec1a-d7c8-4cb8-b3d3-ef496da8f674"
    },
    slamtommingSkjema: {
      uuid: "9951287c-2d0e-4097-b1fc-67c6cd5a687d"
    },
    minirenseanleggSkjema: {
      uuid: "b5790b92-f90d-44c8-93d9-7815aead2005"
    }
  },
  nav: {
    hushald: { title: "Hushald", uri: "/hushald" },
    hytte: { title: "Hytte", uri: "/hytte" },
    avvik: { title: "MELD AVVIK", uri: "/meldingsteneste" },
    naering: {
      title: "Næring",
      uri: "/naering",
      links: [
        { title: "leigecontainerar", uri: "/leigekonteinar" },
        { title: "steinsandgrus", uri: "/steinsandoggrus" },
        { title: "kompostjord", uri: "/kompostjord" },
        { title: "bilvrak", uri: "/bilvrak" },
        { title: "batvrak", uri: "/batvrak" }
      ]
    },
    navigation: {
      services: [
        { title: "Gjenvinningsstasjonar", uri: "/gjenvinningsstasjonar" },
        {
          title: "Adgangsbevis: NGIR-kort / MiljøID-app",
          uri: "/adgangsbevis"
        },
        {
          title: "Tømmekalender",
          uri: "/tommekalender"
        },
        { title: "SMS-varsel frå NGIR", uri: "/smsvarsel" }
      ],
      questions: [
        { title: "Slik sorterer du", uri: "/slik-sorterer-du" },
        {
          title: "Ofte stilte spørsmål",
          uri: "/ofte-stilte-sporsmal"
        },
        { title: "Er du utbyggjar?", uri: "/er-du-utbyggjar" }
      ],
      us: [
        {
          title: "Skulebesøk, strandrydding og berekraft",
          uri: "/strandryddingogskulebesok"
        },
        { title: "Jobba hjå NGIR?", uri: "/jobb" },
        { title: "Om oss", uri: "/omoss" }
      ]
    }
  }
};
